import Image from '../Image/Image';

import styles from './FiftyFiftyCta.module.scss';

const FiftyFiftyCta = ({ title = '', image = {}, link = {} }) => {
    const hasLink = !!link.href;

    return (
        <div className={styles['FiftyFiftyCta']}>
            {hasLink && (
                <a
                    className={styles['FiftyFiftyCta__Link']}
                    href={link.href}
                    target={link.target}>
                    <span className={'sr-only'}>{link.title}</span>
                </a>
            )}
            <div className={styles['FiftyFiftyCta__ImageContainer']}>
                {!!image && !!image.url && (
                    <Image {...{ image }} sizes={'(max-width: 1800px) 828px'} />
                )}
                <h2 className={styles['FiftyFiftyCta__Title']}>{title}</h2>
            </div>
            <span className={styles['FiftyFiftyCta__Button']}>
                {link.title}
            </span>
        </div>
    );
};

FiftyFiftyCta.propTypes = {};

export default FiftyFiftyCta;
