// import PropTypes from 'prop-types';
import FiftyFiftyCta from '../FiftyFiftyCta/FiftyFiftyCta';
import styles from './FiftyFiftyCtaList.module.scss';

const FiftyFiftyCtaList = ({ items = [] }) => {
    return (
        <div className={styles['FiftyFiftyCtaList']}>
            <div className={styles['FiftyFiftyCtaList__Grid']}>
                {items.map((item, index) => (
                    <FiftyFiftyCta key={index} {...item} />
                ))}
            </div>
        </div>
    );
};

FiftyFiftyCtaList.propTypes = {};

export default FiftyFiftyCtaList;
